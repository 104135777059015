import * as THREE from 'three';
import * as React from 'react';
import {useRef, useState} from 'react';
import {Canvas, useFrame} from '@react-three/fiber';
import {Center, Float, Text3D} from '@react-three/drei';

function Logo() {
    const ref = useRef<THREE.Mesh>(null!);
    const [hovered, hover] = useState(false);
    useFrame((state, delta) => (ref.current.rotation.y -= 0.01));

    return (
        <Center position={[0, 1, 0]}>
            <Text3D font={'./VT323_Regular.json'}
                    ref={ref}
                    scale = {3}
                    onClick = {(event) => window.open("https://www.linkedin.com/in/vancefreire/", '_blank')}
                    onPointerOver={(event) => hover(true)}
                    onPointerOut={(event) => hover(false)}
                    onUpdate={mesh => {mesh.geometry.center()}}>
                √
                <meshStandardMaterial color={hovered ? 'limegreen' : 'green'}/>
            </Text3D>
        </Center>
    );
}

function Email() {
    const ref = useRef<THREE.Mesh>(null!);
    const [hovered, hover] = useState(false);

    return (
        <Center position={[0, -1, 0]}>
            <Text3D font={'./VT323_Regular.json'}
                    ref={ref}
                    scale={0.25}
                    onClick={(event) => { window.open('mailto:contact@vancefreire.com')}}
                    onPointerOver={(event) => hover(true)}
                    onPointerOut={(event) => hover(false)}>
                contact@vancefreire.com
                <meshStandardMaterial color={hovered ? 'limegreen' : 'green'}/>
            </Text3D>
        </Center>
    )
}

function Social() {
    const ref = useRef<THREE.Mesh>(null!);
    const [hovered, hover] = useState(false);

    return (
        <Float>
            <Text3D font={'./VT323_Regular.json'}
                    ref={ref}
                    scale={0.25}
                    onClick={(event) => window.open('https://vance.land', '_blank')}
                    onPointerOver={(event) => hover(true)}
                    onPointerOut={(event) => hover(false)}
                    onUpdate={mesh => {mesh.geometry.center()}}>
                π
                <meshStandardMaterial color={hovered ? 'limegreen' : 'green'}/>
            </Text3D>
        </Float>
    );
}

export default function App() {
    return (
        <Canvas>
            <ambientLight intensity={0.5}/>
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1}/>
            <pointLight position={[-10, -10, -10]}/>
            <Center>
                <Logo/>
                <Email/>
            </Center>
            <Center position={[0, -3.5, 0]}>
                <Social />
            </Center>
        </Canvas>
    );
}
